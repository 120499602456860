import React, { FC } from 'react';
import styled from 'styled-components';
import { Col, Form, Row } from 'antd';
import { useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  AssessmentRiskAnalysisItem,
  AssigneeState,
  Question,
} from 'src/store/types';
import { Tooltip } from 'src/components/Tooltip';
import { Rule } from 'antd/lib/form';
import { InfoCircleOutlined } from 'src/theme/icons';
import { getUser } from 'src/store/selectors';
import {
  interviewCreateAssigneeRequestAction,
  interviewDeleteAssigneeRequestAction,
} from 'src/store/actions/interview';
import { confirmDialog } from 'src/shared/utils';
import { GridTag } from 'src/components/Grid';
import {
  THIconNeedHelp,
  THIconOwner,
  THIconRisksStatus,
} from 'src/theme/media';
import { centeredFlex, normalOpenSans, semiboldOpenSans } from 'src/theme';
import { remConvert } from 'src/theme/utils';
import { AssignButton } from '../AssignButton';

export interface InterviewQuestionProps {
  className?: string;
  question: Question;
  children?: React.ReactNode;
  rules?: Rule;
  interviewId?: number;
  assignee?: AssigneeState;
  isLoading?: boolean;
  assessmentRiskAnalysisItem: AssessmentRiskAnalysisItem;
}

interface ContainerProps {
  hasConditionalLogic: boolean;
  isHighlighted: boolean;
  isRiskyQuestion: boolean;
}

const Container = styled.div<ContainerProps>`
  padding: ${remConvert(5)};
  margin: ${remConvert(5)} ${remConvert(0)};
  border: ${({ hasConditionalLogic, theme }) =>
    hasConditionalLogic
      ? `2px dashed ${theme.colors.grayscale.middleGray}`
      : 'none'};
  border-radius: 5px;
  background-color: ${({ theme, isHighlighted }) =>
    isHighlighted ? `${theme.colors.orange.main}60` : 'unset'};
  border: ${({ theme, isRiskyQuestion }) =>
    isRiskyQuestion ? `2px dashed ${theme.colors.red.main}60` : 'none'};
`;

const QuestionMetadataContainer = styled.div`
  font-family: ${({ theme }) => theme.fontFamilies.primary};
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  display: flex;
  flex-direction: column;

  .question-title {
    color: ${({ theme }) => theme.colors.grayscale.darkGray};
    font-size: 14px;
  }

  .question-description {
    color: ${({ theme }) => theme.colors.grayscale.preDark};
    margin: ${remConvert(10)} ${remConvert(0)};
    font-size: 12px;
  }
`;

const TitleContainer = styled.span`
  ${semiboldOpenSans}
  line-height: ${remConvert(22)};
  color: ${({ theme }) => theme.colors.grayscale.darkGray};
  font-size: 16px;
`;

const DeleteAssigneeButton = styled.a`
  color: gray;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  margin-left: ${remConvert(10)};
`;

const RiskTagContainer = styled.div`
  margin-left: ${remConvert(12)};
  margin-bottom: ${remConvert(10)};
`;

const RiskHelpContainer = styled.div`
  margin-left: ${remConvert(12)};
  margin-bottom: ${remConvert(10)};
  line-height: ${remConvert(16)};
  color: ${({ theme }) => theme.colors.sunglo.main};
  ${semiboldOpenSans}
  font-size: 12px;
  cursor: pointer;

  .riskMessage {
    ${centeredFlex}
  }

  img {
    margin-right: ${remConvert(10)};
  }
`;

const NeedHelpContainer = styled.div`
  ${semiboldOpenSans}
  color: ${({ theme }) => theme.colors.astral.main};
  line-height: ${remConvert(16)};
  margin-bottom: ${remConvert(10)};
  font-size: 12px;
  cursor: pointer;
`;

const NeedHelpIconContainer = styled.span`
  padding-left: ${remConvert(10)};
  padding-right: ${remConvert(8)};
`;

const InterviewQuestionOwner = styled.div`
  ${semiboldOpenSans}
  padding-left: ${remConvert(10)};
  font-size: ${remConvert(12)};
  line-height: ${remConvert(16)};
  color: ${({ theme }) => theme.colors.astral.main};

  .assignButtonIcon {
    margin-top: auto;
    margin-bottom: auto;
  }
`;

const InterviewQuestionAssigned = styled.div`
  padding-left: ${remConvert(10)};

  .questionAssigneeLabel {
    ${normalOpenSans}
    font-size: 12px;
    line-height: ${remConvert(16)};
    margin-bottom: ${remConvert(8)};
    color: rgba(0, 0, 0, 0.6);
  }

  .questionAssigneeName {
    ${normalOpenSans}
    font-size: 12px;
    line-height: ${remConvert(16)};
    color: ${({ theme }) => theme.colors.black.main};
  }
`;

export const InterviewQuestion: FC<InterviewQuestionProps> = ({
  className = '',
  question,
  children,
  rules,
  interviewId,
  assignee,
  isLoading = false,
  assessmentRiskAnalysisItem,
}) => {
  const title = question.question ? question.question : question.type.label;
  const isPreview = useRouteMatch('/forms/preview/:id');
  const dispatch = useDispatch();
  const showConditionalMarks =
    (isPreview?.isExact && !!question.conditions?.length) || false;
  const { required, min, max } = question;
  const currentUser = useSelector(getUser);
  const isRiskyQuestion = assessmentRiskAnalysisItem !== undefined;

  return (
    <>
      <Container
        isRiskyQuestion={isRiskyQuestion}
        isHighlighted={currentUser.id === assignee?.assigneeId}
        hasConditionalLogic={showConditionalMarks}
      >
        {question.type.key !== 'section' ? (
          <>
            <Row>
              <Col xs={19}>
                <QuestionMetadataContainer>
                  <Tooltip text={question.tooltip || ''}>
                    <TitleContainer className="question-title">
                      {title}
                      &nbsp;
                      {showConditionalMarks && (
                        <Tooltip text="This question has a Conditional Logic.">
                          <InfoCircleOutlined />
                        </Tooltip>
                      )}
                    </TitleContainer>
                  </Tooltip>
                  {question?.description && (
                    <span className="question-description">
                      {question.description}
                    </span>
                  )}
                </QuestionMetadataContainer>
                <Form.Item
                  className={className}
                  name={question.id || question.internalId}
                  rules={[{ required, min, max, ...rules }]}
                >
                  {children}
                </Form.Item>
              </Col>
              <Col xs={5}>
                <RiskTagContainer>
                  {isRiskyQuestion && (
                    <>
                      {assessmentRiskAnalysisItem &&
                        assessmentRiskAnalysisItem.risk.map((risk: string) => {
                          return (
                            <GridTag
                              key={question.id}
                              type={risk.toLowerCase()}
                            >
                              {risk[0].toUpperCase() +
                                risk.toLocaleLowerCase().slice(1)}{' '}
                              Risk
                            </GridTag>
                          );
                        })}
                    </>
                  )}
                </RiskTagContainer>
                <RiskHelpContainer>
                  {isRiskyQuestion && (
                    <Tooltip
                      text={`Action Item: ${assessmentRiskAnalysisItem.actionItem.reduce(
                        (map: string, ai: string) => {
                          return map + ' ' + ai;
                        },
                        '',
                      )}`}
                    >
                      <div className="riskMessage">
                        <img height={16} width={16} src={THIconRisksStatus} />
                        Risk Help
                      </div>
                    </Tooltip>
                  )}
                </RiskHelpContainer>
                {/* <NeedHelpContainer>
                  <NeedHelpIconContainer>
                    <img src={THIconNeedHelp} />
                  </NeedHelpIconContainer>
                  <span>Need Help?</span>
                </NeedHelpContainer> */}
                <InterviewQuestionOwner>
                  <AssignButton
                    hide={!!assignee}
                    buttonTooltip="Delegate someone to this question"
                    modalTitle="Assign Risk Question"
                    autoCompleteTitle={question.question}
                    isQuestion
                    onOk={(selectedAssigneeId?: number) => {
                      if (interviewId && selectedAssigneeId && question?.id) {
                        dispatch(
                          interviewCreateAssigneeRequestAction(
                            interviewId || 0,
                            selectedAssigneeId || 0,
                            question?.id || 0,
                          ),
                        );
                      }
                    }}
                  />
                </InterviewQuestionOwner>
                {assignee && (
                  <InterviewQuestionAssigned>
                    <span>
                      <div className="questionAssigneeLabel">
                        Question assigned to:
                      </div>
                      <span className="questionAssigneeName">
                        <img src={THIconOwner} />
                        <span>{assignee?.assigneeName}</span>
                      </span>
                      <span>
                        <DeleteAssigneeButton
                          onClick={() => {
                            !isLoading &&
                              confirmDialog({
                                text: 'Are you sure you want to unassign this question?',
                                onOk: () => {
                                  dispatch(
                                    interviewDeleteAssigneeRequestAction(
                                      assignee.interviewId,
                                      assignee.id,
                                    ),
                                  );
                                },
                              });
                          }}
                        >
                          x
                        </DeleteAssigneeButton>
                      </span>
                    </span>
                  </InterviewQuestionAssigned>
                )}
              </Col>
            </Row>
          </>
        ) : (
          children
        )}
      </Container>
      <hr />
    </>
  );
};
