/* eslint-disable @typescript-eslint/no-floating-promises */
import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Popconfirm, Form, Tabs, Spin } from 'antd';
import moment from 'moment';
import { FilePdfOutlined, LoadingOutlined } from '@ant-design/icons';
import download from 'downloadjs';

import { Button } from 'src/components/Button';
import { TopNavbar } from 'src/components/TopNavbar';
import { HippaRiskAssessmentTable } from 'src/features/risk-assessment/components/RiskAssessmentTable';
import { SlidingPanel } from 'src/components/SlidingPanel';
import { SetCompletionDateForm } from 'src/features/risk-assessment/components/SetCompletionDateForm';
import { confirmDialog } from 'src/shared/utils';
import s3 from 'src/services/downloadFile';
import {
  RootState,
  DocumentState,
  SelectedDocumentState,
  isAssessmentReportDocument,
  FormState,
  InterviewListingIndexedItem,
  AssessmentStatus,
  DocumentStatus,
  AssessmentReportDocument,
  AssessmentTypeLabel,
} from 'src/store/types';
import {
  getSelectedDocument,
  getDocumentById,
  getAssessmentById,
  getAnswerFromDocumentData,
  getThreadsByQuestionId,
  getCompaniesLoader,
  getIndexedDocumentsDraftsFromState,
  getSelectedDocumentLoader,
  getAssessmentTypeLabel,
  getCompanyFromState,
} from 'src/store/selectors';
import {
  documentLoadRequest,
  setSelectedDocument,
  documentSetInClientReviewRequest,
  documentMarkAcceptedRequest,
  documentApproveRequest,
} from 'src/store/actions/document';
import { threadsLoadRequest } from 'src/store/actions/threadListing';
import {
  assessmentLoadRequest,
  assessmentUpdateStatusRequest,
} from 'src/store/actions/assessment';
import { companiesLoadRequest } from 'src/store/actions/companyListing';
import { useAuth } from 'src/hooks/useAuth/useAuth';
import { companyLoadRequest } from 'src/store/actions/company';
import { commentShowSelectedQuestion } from 'src/store/actions/comment';
import { templatesLoadRequest } from 'src/store/actions/templateListing';
import { documentDraftsLoadRequest } from 'src/store/actions/documentDraftListing';
import { RiskAssessmentRiskFieldListingTable } from 'src/features/risk-assessment/components/RiskAssesmentRiskFieldListingTable';
import { WithAssessmentTypeTable } from 'src/features/risk-assessment/components/RiskAssessmentTable/WithAssessmentTypeTable';
import { assessmentTypesLoadLabelsRequest } from 'src/store/actions/assessmentTypes';
import { SideDialogBox } from 'src/components/SideDialogBox';
import { api } from 'src/api/services/ThApi';
import { showErrorMessage } from 'src/store/actions/error';

interface RouteParams {
  id: string;
  assessmentId: string;
  documentId: string;
}

const PageContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 22px;
`;

const MainColumn = styled.div`
  width: calc(98% - ${({ theme }) => `${theme.sidebar.width}px`});
`;

const SecondaryColumn = styled.div`
  width: calc(
    100% - ${({ theme }) => `${theme.sidebar.riskAssesmentCommentWidth}px`}
  );
  display: flex;
  justify-content: center;
`;

const Title = styled.span`
  color: ${({ theme }) => theme.colors.deepBlue.main};
  font-family: ${({ theme }) => theme.fontFamilies.primary};
  font-size: 18px;
  font-weight: 600;
  height: 50px;
  display: inline-flex;
  align-items: center;
  background-color: #f3f3f3;
  position: sticky;
  top: 0;
  z-index: 9;
  padding: 16px 0;
  box-sizing: border-box;
`;

const FormAssessmentContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;

  & .ant-table table .ant-table-thead {
    position: sticky;
    top: 40px;
    z-index: 8;

    & > tr > th {
      padding-bottom: 16px;
    }
  }
`;

const { TabPane } = Tabs;

const StyledTab = styled(Tabs)`
  & .ant-tabs-nav-list {
    height: 30px;
  }
`;

const ReportSummaryPanel = styled(TabPane)`
  overflow: hidden;
  height: calc(80vh - 72px);
  border-radius: 10px;
`;

const ReportSummaryContainer = styled.div`
  overflow: auto;
  height: 100%;
`;

export enum RiskAssessmentType {
  HIPPA = 'HIPAA',
  HIPAA_PRIME = 'HIPAA Prime',
  NIST_800_171 = 'NIST 800-171',
  GLBA = 'GLBA',
  CMMC = 'CMMC',
}

export const AssessmentReportPage: FC<unknown> = () => {
  const { user, isAdmin, isCompanyModerator, isOfficer } = useAuth();
  const dispatch = useDispatch();
  const history = useHistory();

  const company = useSelector(getCompanyFromState);

  const [completionDatePanelVisible, setCompletionDatePanelVisible] =
    useState(false);
  const [writeCommentPanelVisible, setWriteCommentPanelVisible] =
    useState(false);
  const [selectedAnswerId, setSelectedAnswerId] = useState<number | undefined>(
    undefined,
  );
  const [selectedInterviewId, setSelectedInterviewId] = useState<
    number | undefined
  >(undefined);
  const [selectedCommentId, setSelectedCommentId] = useState<
    number | undefined
  >(undefined);
  const [selectedQuestionId, setSelectedQuestionId] = useState<
    number | undefined
  >(undefined);
  const [selectedThreadId, setSelectedThreadId] = useState<number | undefined>(
    undefined,
  );
  const [replyingToCommentId, setReplyingToCommentId] = useState<
    number | undefined
  >(undefined);
  const [isGeneratingReportSummary, setIsGeneratingReportSummary] =
    useState<boolean>(false);
  const [isGeneratingRiskReportSummary, setIsGeneratingRiskReportSummary] =
    useState<boolean>(false);

  const {
    id: rawCompanyId,
    assessmentId: rawAssessmentId,
    documentId: rawDocumentId,
  } = useParams<RouteParams>();
  const companyId = +rawCompanyId;
  const assessmentId = +rawAssessmentId;
  const documentId = +rawDocumentId;

  const document = useSelector((state: RootState): DocumentState | undefined =>
    getDocumentById(state, documentId),
  );
  const companiesLoader = useSelector(getCompaniesLoader);
  const selectedDocument: SelectedDocumentState =
    useSelector(getSelectedDocument);
  const [isLinkedResourceFirstCall, setIsLinkedResourceFirstCall] = useState(
    selectedDocument.document.id === 0,
  );
  const assessment = useSelector((state: RootState) =>
    getAssessmentById(state, assessmentId),
  );
  const selectedAnswer = useSelector((state: RootState) =>
    getAnswerFromDocumentData(state, selectedAnswerId),
  );
  const threadsByQuestionId = useSelector((state: RootState) =>
    getThreadsByQuestionId(state),
  );
  const documentDrafts = useSelector((state: RootState) =>
    getIndexedDocumentsDraftsFromState(state, '', !isAdmin),
  );
  const selectedDocumentLoader = useSelector((state: RootState) =>
    getSelectedDocumentLoader(state),
  );
  const reportName = useSelector((state: RootState) =>
    getAssessmentTypeLabel(state, AssessmentTypeLabel.REPORT_NAME),
  );

  useEffect(() => {
    if (isLinkedResourceFirstCall && !selectedDocument.loader.success) {
      dispatch(documentLoadRequest(documentId));
      setIsLinkedResourceFirstCall(false);
    }
  }, [selectedDocument.loader.success]);

  const [answerEditForm] = Form.useForm();
  const newCommentFormRef = useRef<HTMLDivElement>(null);
  const isOnlyOfficer = isOfficer && !isAdmin;

  useEffect(() => {
    if (document) {
      dispatch(setSelectedDocument(document));
      dispatch(documentLoadRequest(document.id));
      dispatch(documentDraftsLoadRequest(document.id));
    }
  }, [document]);

  useEffect(() => {
    if (
      user &&
      companiesLoader &&
      !(companiesLoader.completed || companiesLoader.loading)
    ) {
      if (isCompanyModerator) {
        dispatch(companiesLoadRequest());
      }
    }
    dispatch(threadsLoadRequest({ assessmentId: Number(assessmentId) }));
  }, []);

  useEffect(() => {
    if (selectedAnswer && completionDatePanelVisible) {
      const completionDate = moment(selectedAnswer.actionItemCompletedAt);
      const parsedCompletionDate = completionDate.isValid()
        ? completionDate
        : null;
      answerEditForm.setFieldsValue({
        actionItemCompletedAt: parsedCompletionDate,
      });
    }
  }, [selectedAnswer, completionDatePanelVisible]);

  useEffect(() => {
    dispatch(
      companyLoadRequest(companyId, {
        withAssessments: true,
      }),
    );
    console.log(company);
  }, [companyId]);

  useEffect(() => {
    if (assessment) {
      dispatch(assessmentTypesLoadLabelsRequest(assessment.typeId));
    }

    if (assessment && assessment.companyId) {
      // Need to know all the users from this company
      dispatch(companyLoadRequest(assessment.companyId));
      if (isAdmin) {
        dispatch(templatesLoadRequest(assessment.typeId));
      }
    }
  }, [assessment]);

  useEffect(() => {
    dispatch(
      assessmentLoadRequest(assessmentId, {
        withCompany: true,
        withForms: true,
        withType: true,
      }),
    );
  }, [assessmentId]);

  const getTopNavbarButtons = () => {
    if (document?.status === DocumentStatus.CLOSED) return [];

    const buttons =
      isAdmin && assessment && assessment.status === AssessmentStatus.COMPLETED
        ? [
            <Popconfirm
              title={
                'Doing this will allow for modifications in any form of the assessment and generation of new documents'
              }
              onConfirm={() => {
                dispatch(
                  assessmentUpdateStatusRequest(
                    assessment.id,
                    AssessmentStatus.REOPEN,
                  ),
                );
                history.push(
                  `/companies/${companyId}/assessments/${assessmentId}/assessmentReports`,
                );
              }}
              okText="Yes"
              cancelText="No"
              key={0}
            >
              <Button title="Reopen Assessment" variant="secondary" />
            </Popconfirm>,
            <Popconfirm
              title={
                'Doing this will display all the risks to the officer in the RA forms and re-open the assessment'
              }
              onConfirm={() => {
                dispatch(
                  assessmentUpdateStatusRequest(
                    assessment.id,
                    AssessmentStatus.MITIGATION,
                  ),
                );
                history.push(
                  `/companies/${companyId}/assessments/${assessmentId}/assessmentReports`,
                );
              }}
              okText="Yes"
              cancelText="No"
              key={1}
            >
              <Button title="Mitigate" variant="danger" />
            </Popconfirm>,
          ]
        : [];

    if (
      (!isAdmin &&
        documentDrafts &&
        Object.values(documentDrafts).length > 0) ||
      isAdmin
    ) {
      buttons.push(
        <Button
          title="View Documents Drafts"
          key={'view-document-drafts-button-assessment-report-page'}
          onClick={() => {
            history.push(
              `/companies/${companyId}/assessments/${assessmentId}/assessmentReports/${documentId}/documentDrafts`,
            );
          }}
        />,
      );
    }

    if (
      isAdmin &&
      selectedDocument &&
      (selectedDocument.document?.status === DocumentStatus.ACTIVE ||
        selectedDocument.document?.status === DocumentStatus.ACCEPTED)
    ) {
      buttons.push(
        <Button
          title="Start Client Review"
          key={2}
          onClick={() => {
            confirmDialog({
              text: 'Are you sure you want to start client review for this document?',
              onOk: () => {
                dispatch(documentSetInClientReviewRequest(Number(documentId)));
              },
            });
          }}
        />,
      );
    }

    if (
      isOnlyOfficer &&
      selectedDocument &&
      selectedDocument.document?.status === DocumentStatus.CLIENT_REVIEW
    ) {
      buttons.push(
        <Button
          title="Submit comments for review"
          key={2}
          onClick={() => {
            confirmDialog({
              text: 'Are you sure you want to submit your comments for review?',
              onOk: () => {
                dispatch(documentMarkAcceptedRequest(Number(documentId)));
              },
            });
          }}
        />,
      );
    }

    if (
      isAdmin &&
      selectedDocument &&
      selectedDocument.document?.status === DocumentStatus.ACCEPTED
    ) {
      buttons.push(
        <Button
          title="Finalize"
          key={2}
          onClick={() => {
            confirmDialog({
              text: 'Are you sure you want to finalize for this document?',
              onOk: () => {
                dispatch(documentApproveRequest(Number(documentId)));
              },
            });
          }}
        />,
      );
    }

    return buttons;
  };

  const handleStartNewDiscussion = () => {
    setReplyingToCommentId(undefined);
    setSelectedCommentId(undefined);
    setWriteCommentPanelVisible(true);
  };

  const handleOpenDiscussion = (questionId: number) => {
    setSelectedQuestionId(questionId);

    if (threadsByQuestionId[questionId]) {
      setSelectedThreadId(threadsByQuestionId[questionId].id);
      dispatch(commentShowSelectedQuestion(`question${questionId}`));
    } else {
      setSelectedThreadId(undefined);
      handleStartNewDiscussion();

      if (newCommentFormRef && newCommentFormRef.current) {
        newCommentFormRef.current.scrollIntoView();
      }
    }
  };

  const handleReply = (parentId: number, replyingToId: number) => {
    setReplyingToCommentId(replyingToId);
    setSelectedCommentId(parentId);
    setWriteCommentPanelVisible(true);
  };

  const handleDownloadReportSummary = () => {
    if (document) {
      setIsGeneratingReportSummary(true);

      api
        .getAssessmentReportUrl(document.id)
        .then((data: any) => {
          s3.handleFileDownloadSignedUrl(data.assessmentReportPdfUrl);
          setIsGeneratingReportSummary(false);
        })
        .catch((err) => {
          setIsGeneratingReportSummary(false);
          dispatch(showErrorMessage(null, err.message, err));
        });
    }
  };

  const handleDownloadRiskSummary = () => {
    if (document) {
      setIsGeneratingRiskReportSummary(true);
      api
        .getRiskSummaryReportUrl(document.id)
        .then((res: any) => {
          setIsGeneratingRiskReportSummary(false);
          const blob = new Blob([res], { type: 'application/pdf' });
          download(blob, 'Risks-Summary.pdf');
        })
        .catch((err) => {
          setIsGeneratingRiskReportSummary(false);
          dispatch(showErrorMessage(null, err.message, err));
        });
    }
  };

  React.useLayoutEffect(() => {
    if (
      writeCommentPanelVisible &&
      newCommentFormRef &&
      newCommentFormRef.current
    ) {
      newCommentFormRef.current.scrollIntoView();
    }
  }, [writeCommentPanelVisible]);

  const file = useMemo(() => {
    const { file } = selectedDocument.document;
    if (file && isAssessmentReportDocument(file))
      return file as AssessmentReportDocument;
    return undefined;
  }, [selectedDocument.document]);

  const formList: FormState[] = useMemo(() => {
    if (file) {
      const { interviews, forms } = file;
      return forms.filter(
        (form) =>
          interviews.findIndex((interview) => interview.formId === form.id) >
          -1,
      );
    }
    return [];
  }, [file]);

  const renderDocumentFile = () => {
    if (file) {
      const { assessment, interviews } = file;

      const interviewsByFormId: InterviewListingIndexedItem = interviews.reduce(
        (interviewMap, interview) => {
          return {
            ...interviewMap,
            [interview.formId]: interview,
          };
        },
        {},
      );

      const RiskAssessmentReportTable = WithAssessmentTypeTable(
        HippaRiskAssessmentTable,
        dispatch,
      );

      const antIcon = <LoadingOutlined spin />;

      return (
        <StyledTab type="line" size="large">
          <ReportSummaryPanel tab="Report Summary" key={1}>
            {!isGeneratingReportSummary && isAdmin && (
              <a onClick={handleDownloadReportSummary}>
                <FilePdfOutlined /> Download Report Summary (PDF)
              </a>
            )}
            {isGeneratingReportSummary && (
              <>
                <Spin indicator={antIcon} />{' '}
                <a> Generating Report Summary...</a>
              </>
            )}
            <ReportSummaryContainer>
              {formList.map((form: FormState) => (
                <FormAssessmentContainer key={form.id}>
                  <Title>{form.label || `Form #${form.id}`}</Title>
                  {
                    <RiskAssessmentReportTable
                      form={form}
                      interview={interviewsByFormId[form.id]}
                      assesmentType={assessment.type.label}
                      disableReviewSettings={
                        document
                          ? document.status !== DocumentStatus.CLIENT_REVIEW &&
                            document.status !== DocumentStatus.ACTIVE
                          : false
                      }
                      selectedQuestionId={selectedQuestionId}
                      setSelectedAnswerId={setSelectedAnswerId}
                      setCompletionDatePanelVisible={
                        setCompletionDatePanelVisible
                      }
                      setSelectedInterviewId={setSelectedInterviewId}
                      handleOpenDiscussion={handleOpenDiscussion}
                      companyName={company.name}
                    />
                  }
                </FormAssessmentContainer>
              ))}
            </ReportSummaryContainer>
          </ReportSummaryPanel>
          <TabPane tab="Risks Summary" key={2}>
            <RiskAssessmentRiskFieldListingTable
              forms={formList}
              interviews={interviewsByFormId}
              isGeneratingRiskSummary={isGeneratingRiskReportSummary}
              handleRiskSummaryDownload={handleDownloadRiskSummary}
            />
          </TabPane>
        </StyledTab>
      );
    }

    return null;
  };

  const handleSaveButtonPress = () => {
    answerEditForm.submit();
    setCompletionDatePanelVisible(false);
  };

  const handleFormClose = () => {
    setWriteCommentPanelVisible(false);
    setSelectedQuestionId(undefined);
    setSelectedThreadId(undefined);
  };

  return (
    <>
      <TopNavbar
        title={reportName}
        prevRoute={
          isAdmin
            ? {
                name: 'Assessment Reports',
                url: `/companies/${companyId}/assessments/${assessmentId}/assessmentReports`,
              }
            : {
                name: 'Dashboard',
                url: `/dashboard`,
              }
        }
        extraOptions={getTopNavbarButtons()}
        withCompany
        sticky
      />
      <PageContentContainer>
        {!selectedDocumentLoader.loading && (
          <MainColumn>{renderDocumentFile()}</MainColumn>
        )}
        {selectedDocumentLoader.loading && (
          <SecondaryColumn>
            {
              <Spin
                size="large"
                tip="Loading assessment's snapshot file..."
                spinning={selectedDocumentLoader.loading}
              />
            }
          </SecondaryColumn>
        )}
        <SlidingPanel
          title="Set the Completion Date"
          visible={completionDatePanelVisible}
          saveLabel="Save"
          onClose={() => setCompletionDatePanelVisible(false)}
          onSave={handleSaveButtonPress}
        >
          <SetCompletionDateForm
            form={answerEditForm}
            answerId={selectedAnswerId}
            interviewId={selectedInterviewId}
          />
        </SlidingPanel>
        <SideDialogBox
          assessmentId={assessmentId}
          companyId={companyId}
          formList={formList}
          documentId={documentId}
          threadId={selectedThreadId}
          selectedQuestionId={selectedQuestionId}
          selectedCommentId={selectedCommentId}
          replyingToCommentId={replyingToCommentId}
          writeCommentPanelVisible={writeCommentPanelVisible}
          onClose={handleFormClose}
          setSelectedQuestionId={setSelectedQuestionId}
          setSelectedThreadId={setSelectedThreadId}
          handleStartNewDiscussion={handleStartNewDiscussion}
          handleReply={handleReply}
          onClosePanel={() => setWriteCommentPanelVisible(false)}
        />
      </PageContentContainer>
    </>
  );
};
