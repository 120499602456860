import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { message, Result } from 'antd';

import { TopNavbar } from 'src/components/TopNavbar';
import { companyLoadRequest } from 'src/store/actions/company';
import {
  RootState,
  AssessmentState,
  AssessmentStatus,
  Loader,
} from 'src/store/types';
import { LoaderComponent } from 'src/components/Loader';
import {
  getFormsByCompanyLastAssessment,
  getCompanyUsers,
  getSelectedInterview,
  getInterviewsFromLastAssessmentByFormId,
  getCompanyLastAssessment,
  getCompanyAssessmentRiskAnalysis,
  getCompanyAssessmentRiskAnalysisLoader,
  getCompanyLoaderFromState,
  getCurrentExam,
  getFormListingLoader,
} from 'src/store/selectors';
import { InterviewListing } from 'src/features/risk-assessment/components/InterviewListing';
import { useAuth } from 'src/hooks/useAuth/useAuth';
import { isEmptyObject } from 'src/shared/utils';
import { categoryLoadRequest } from 'src/store/actions/category';
import actions from 'src/store/actions';
import * as S from './Styles';

export const AssessmentPage: FC<unknown> = () => {
  const [showCompleteModal, setShowCompleteModal] = useState(false);
  const [completeAssessmentLoading, setCompleteAssessmentLoading] =
    useState(false);
  const [adjustScroll, setAdjustScroll] = useState(true);
  const [displayedCompletionModal, setDisplayedCompletionModal] =
    useState(false);
  const [pageLoaded, setPageLoaded] = useState<boolean>(false);
  const { isOfficer, isAdmin, isCompanyModerator, user } = useAuth();

  const forms = useSelector((state: RootState) =>
    getFormsByCompanyLastAssessment(state, {}),
  );
  const formsLoader = useSelector((state: RootState) =>
    getFormListingLoader(state),
  );
  const interviews = useSelector((state: RootState) =>
    getInterviewsFromLastAssessmentByFormId(state),
  );
  const users = useSelector((state: RootState) => getCompanyUsers(state));
  const selectedInterview = useSelector((state: RootState) =>
    getSelectedInterview(state),
  );
  const assessment = useSelector((state: RootState) =>
    getCompanyLastAssessment(state),
  ) as AssessmentState;
  const currentExam = useSelector(getCurrentExam);
  const assessmentRiskAnalysis = useSelector((state: RootState) =>
    getCompanyAssessmentRiskAnalysis(state),
  );
  const assessmentRiskAnalysisLoader = useSelector((state: RootState) =>
    getCompanyAssessmentRiskAnalysisLoader(state),
  );
  const companyLoader = useSelector(
    (state: RootState): Loader => getCompanyLoaderFromState(state),
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(categoryLoadRequest());
  }, []);

  useEffect(() => {
    if (formsLoader.completed && companyLoader.completed) {
      setPageLoaded(true);
    }
  }, [formsLoader, companyLoader, assessmentRiskAnalysisLoader]);

  useEffect(() => {
    if (adjustScroll && forms) {
      const container = document.querySelector('.ant-layout-content');

      if (container) {
        container.scrollTop = 0;
        setAdjustScroll(false);
      }
    }
  }, [forms]);

  useEffect(() => {
    if (user.company) {
      dispatch(companyLoadRequest(user.company.id));
    }
  }, [user]);

  useEffect(() => {
    if (shouldDisplayCompletionModal()) {
      displayCompletionModal();
    }
  }, [interviews]);

  useEffect(() => {
    if (assessment) {
      if (assessment.status === AssessmentStatus.COMPLETED) {
        setCompleteAssessmentLoading(false);
        setShowCompleteModal(false);
      }

      if (shouldLoadAssessmentRisks()) {
        dispatch(actions.assessmentLoadRiskAnalysisRequest(assessment.id));
      }

      if (shouldLoadExam()) {
        dispatch(actions.examLoadRequest());
      }
    }
  }, [assessment]);

  useEffect(() => {
    if (selectedInterview.metadata.saveCompleted) {
      void message.success('Interview saved successfully');
    }
  }, [selectedInterview.metadata.saveCompleted]);

  const shouldDisplayCompletionModal = () => {
    return (
      !isEmptyObject(interviews) &&
      areFormsCompleted() &&
      !showCompleteModal &&
      assessment?.status !== AssessmentStatus.COMPLETED &&
      !displayedCompletionModal
    );
  };

  const shouldLoadExam = () => {
    return (
      !isAdmin &&
      !isCompanyModerator &&
      isOfficer &&
      assessment.status &&
      !(
        assessment.status === AssessmentStatus.MITIGATION ||
        assessment.status === AssessmentStatus.REOPEN ||
        assessment.status === AssessmentStatus.COMPLETED
      )
    );
  };

  const shouldLoadAssessmentRisks = () => {
    return (
      assessment.id &&
      assessment.status === AssessmentStatus.MITIGATION &&
      !assessmentRiskAnalysisLoader.loading
    );
  };

  const displayCompletionModal = () => {
    setShowCompleteModal(true);
    setDisplayedCompletionModal(true);
  };

  const areFormsCompleted = () => {
    return (
      forms?.totalItems !== 0 &&
      forms?.totalItems === Object.keys(interviews).length &&
      Object.values(interviews).every((i) => !!i.completedAt)
    );
  };

  const completeAssessment = () => {
    if (assessment) {
      dispatch(
        actions.assessmentUpdateStatusRequest(
          assessment.id,
          AssessmentStatus.COMPLETED,
        ),
      );
      setCompleteAssessmentLoading(true);
    }
  };

  const mustShowCompleteAssessmentButton = (): boolean => {
    return (
      isOfficer &&
      !isAdmin &&
      areFormsCompleted() &&
      assessment &&
      assessment.status !== AssessmentStatus.COMPLETED
    );
  };

  const canTakeAssessment = (): boolean => {
    return (
      (isOfficer && currentExam?.hasPastPassedSubmissions) ||
      user.allowSkipToRiskAssessment ||
      assessment.status === AssessmentStatus.MITIGATION ||
      assessment.status === AssessmentStatus.REOPEN ||
      assessment.status === AssessmentStatus.COMPLETED ||
      false
    );
  };

  const isMitigationMode = (): boolean => {
    return assessment.status === AssessmentStatus.MITIGATION;
  };

  const canViewAssessmentRestriction = (): boolean => {
    return isOfficer;
  };

  const getDashboard = () => {
    if (isCompanyModerator) {
      return <></>;
    }

    if (canTakeAssessment()) {
      return (
        <>
          {canTakeAssessment() && (
            <InterviewListing
              forms={forms.data}
              interviews={interviews}
              users={users}
              assessment={assessment}
              assessmentRiskAnalysis={assessmentRiskAnalysis}
              isMitigationMode={isMitigationMode()}
              onCardClick={() => setAdjustScroll(true)}
              canSubmitRiskAssessment={
                mustShowCompleteAssessmentButton() && canTakeAssessment()
              }
              onSubmitRiskAssessment={completeAssessment}
              completeAssessmentLoading={completeAssessmentLoading}
            />
          )}
        </>
      );
    } else {
      {
        return (
          <>
            {canViewAssessmentRestriction() && !canTakeAssessment() && (
              <Result title="The Risk Assessment will become available after your individual HIPAA training is completed." />
            )}
          </>
        );
      }
    }
  };

  const description =
    canViewAssessmentRestriction() && !canTakeAssessment()
      ? ''
      : 'Your Risk Assessment is now available. Please complete each section and review all questions for the current year. After all sections are marked “Completed”, you may submit the Risk Assessment for analysis.';

  return (
    <div id="main-container">
      <TopNavbar title="Risk Assessment" description={description} />
      <S.PageContentContainer>
        <LoaderComponent opacity={pageLoaded ? 0 : 100} />
        {getDashboard()}
      </S.PageContentContainer>
    </div>
  );
};
