import { DefaultTheme } from 'styled-components';
import { basePalette } from './palette';
import { OpenSans, Montserrat, Roboto } from './fonts/fonts';

export const mainTheme: DefaultTheme = {
  fontFamilies: {
    primary: OpenSans,
    secondary: Montserrat,
    terciary: Roboto,
  },
  fontSizes: {
    h1: 26,
    h2: 22,
    h3: 14,
    h4: 12,
    h5: 12,
    h6: 12,
    body: 16,
    rectBtn: 10,
    roundBtn: 13,
  },
  colors: { ...basePalette },
  sidebar: {
    width: 424,
    riskAssesmentCommentWidth: 400,
    smallColumnWidth: 300,
  },
  breakpoints: {
    480: '480px',
    576: '576px',
    768: '768px',
    992: '992px',
    1200: '1200px',
    1600: '1600px',
  },
  fontStyles: {
    normal: 'normal',
    italic: 'italic',
  },
  lineHeights: {
    22: 22,
  },
  fontWeights: {
    thin: 100,
    extraLight: 200,
    light: 300,
    normal: 400,
    medium: 500,
    semiBold: 600,
    bold: 700,
    extraBold: 800,
  },
};
